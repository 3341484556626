import { AxiosError, AxiosResponse } from 'axios';
import HttpStatusCodes from '@/services/common/HttpStatusCodes';
import { notifyError, notifyWarn } from '@/utils/NotificationUtils';
import * as Sentry from '@sentry/browser';
import { logoutUser } from '@/store';


export const onResponseSuccess = (response: AxiosResponse): AxiosResponse => response;

export const onResponseFailure = (error: AxiosError): Promise<AxiosError> => {
  Sentry.captureException(JSON.stringify(error))
  const httpStatus = error?.response?.status;
  switch (httpStatus) {
    case HttpStatusCodes.UNAUTHORIZED:
      if (!error.response?.config?.url?.includes('credentials')) {
        notifyWarn('Twoja sesja wygasła. Zaloguj się ponownie by korzystać z aplikacji.');
        logoutUser();
      }
      break;
    case HttpStatusCodes.BAD_REQUEST:
      return Promise.reject(error.response?.data);
    case HttpStatusCodes.NOT_FOUND:
      notifyError('Wystąpił błąd, spróbuj jeszcze raz później.');
      break;
    case HttpStatusCodes.FORBIDDEN:
      notifyError('Nie masz dostępu do tego zasobu.');
      break;
    case HttpStatusCodes.UNPROCESSABLE_ENTITY:
      // This case should be handled at the forms
      break;
    case HttpStatusCodes.INTERNAL_SERVER_ERROR:
      return Promise.reject(error.response?.data);
    default:
      notifyError('Wystąpił błąd, spróbuj jeszcze raz później.')
      break;
  }
  return Promise.reject({ ...error.response?.data, statusCode: error.response?.status });
};
