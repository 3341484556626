import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { setUserDataFromStorage, setEnvs, saveStorageInfoToStore, } from '@/store';
import initSentry from '@/plugins/sentry'
import apiGt from '@/services/api/ApiGT';
import apiOmr from '@/services/api/ApiOmr';
import apiMne from '@/services/api/ApiMne';
import piwik from '@/plugins/piwik';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import i18n from './i18n'

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import { getEnvVariables } from '@/services/api/Env';
import { applyPolyfills, defineCustomElements } from 'gt-test/dist/loader';
import { defineCustomElements as defineCustomElementsIonic} from '@ionic/pwa-elements/loader';

const initApp = async () => {
  await saveStorageInfoToStore();
  await setUserDataFromStorage();
  const envs = await getEnvVariables();
  await setEnvs(envs);
  await applyPolyfills();
  await defineCustomElements(window);
  await defineCustomElementsIonic(window);
  initSentry(envs.env);
  // TODO: remove after env is added by devops
  piwik(envs.piwikId || '9b8adf94-1ae2-4787-9750-f0351c8db246');



  apiGt.defaults.baseURL = envs.apiUrl;
  apiOmr.defaults.baseURL = envs.omrUrl;
  apiMne.defaults.baseURL = envs.mneUrl;

  const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(i18n)

  router.isReady().then(() => {
    app.mount('#app');
  });
}

initApp();
