
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { computed, defineComponent, onMounted } from 'vue';
import { KeepAwake } from "@capacitor-community/keep-awake";
import { isLogged } from '@/store';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup () {
    const isLoggedIn = computed(() => isLogged.value);
    onMounted(async () => {
      await KeepAwake.keepAwake();
      if (isLoggedIn.value) dataLayerPush(DataLayerEvent.USER_LOGIN, { isSuccess: true });
    })
  },
});
