import { createRouter, createWebHistory } from '@ionic/vue-router';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import Tabs from '@/views/Tabs.vue'
import { RouteName } from '@/router/routeNames';
import { isLogged, loginStatus, userData } from '@/store';
import PageNotFound from '@/components/PageNotFound.vue';
import Login from '@/views/Login.vue';
import { LoginStatus } from '@/models';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/AnswerCard/AnswerCard.vue'),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (isLogged.value && loginStatus.value === LoginStatus.SUCCESS && userData.value.isOndorio) {
        next(RouteName.ANSWER_CARD);
      } else {
        next(RouteName.LOGIN);
      }
    }
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: `/${RouteName.HOME}`,
        component: () => import('@/views/Home/Home.vue'),
        name: RouteName.HOME
      },
      {
        path: `/${RouteName.ANSWER_CARD}`,
        name: RouteName.ANSWER_CARD,
        component: () => import('@/views/AnswerCard/AnswerCard.vue'),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (isLogged.value && loginStatus.value === LoginStatus.SUCCESS && userData.value.isOndorio) {
            next();
          } else if (isLogged.value && loginStatus.value === LoginStatus.SUCCESS && !userData.value.isOndorio) {
            next(RouteName.NO_ONDORIO);
          } else {
            next(RouteName.LOGIN);
          }
        }
      },
      {
        path: `/${RouteName.LOGIN}`,
        name: RouteName.LOGIN,
        component: Login,
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (isLogged.value) {
            const redirectPage = loginStatus.value === LoginStatus.SUCCESS ? RouteName.ANSWER_CARD : RouteName.LOGIN
            // hard reload due to GT-5024
            window.location.href = `/${redirectPage}`
            next(redirectPage);
          } else {
            next();
          }
        }
      },
      {
        path: `/${RouteName.NOT_VERIFIED}`,
        name: RouteName.NOT_VERIFIED,
        component: () => import('@/views/Unverified.vue'),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (loginStatus.value === null || loginStatus.value === LoginStatus.SUCCESS) {
            next(RouteName.HOME);
          } else {
            next();
          }
        }
      },
      {
        path: `/${RouteName.NO_ONDORIO}`,
        name: RouteName.NO_ONDORIO,
        component: () => import('@/views/NoOndorioUser.vue'),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (userData.value.isOndorio) {
            next(RouteName.ANSWER_CARD);
          } else {
            next();
          }
        }
      },
      {
        path: `/${RouteName.COOKIES_POLICY}`,
        name: RouteName.COOKIES_POLICY,
        component: () => import('@/views/CookiesPolicy.vue'),
      },

    ]
  },
  { path: '/:catchAll(.*)', component: PageNotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  dataLayerPush(DataLayerEvent.PAGE_VIEW, { from: from.name, to: to.name });
  next();
})

export default router
