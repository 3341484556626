

import { defineComponent } from 'vue';
import { IonModal, IonIcon, IonContent } from '@ionic/vue';
import { ModalWidthEnum } from '@/models';

export default defineComponent({
  name: 'Modal',
  components: { IonModal, IonIcon, IonContent },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
    },
    width: {
      type: String,
      default: 'md'
    }
  },
  data () {
    return {
      widthEnum: ModalWidthEnum
    }
  },
  methods: {
    setIsModalOpen(isOpen: boolean): void {
      this.$emit('onModalOpen', isOpen);
    },
    setModalHeight (): void {
      if (this.height) {
        setTimeout(() => {
          const items = document.getElementsByClassName('modal-wrapper') as any;
          items[0].style.height = `${this.height}px`
        }, 100);
      }
    },
  },
  mounted () {
    this.setModalHeight();
  },
})
