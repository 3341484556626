export enum RouteName {
  HOME = 'home',
  ANSWER_CARD = 'answer-card',
  LOGIN = 'login',
  NOT_VERIFIED = 'not-verified',
  NO_ONDORIO = 'no-ondorio',
  DELIMITER = '/',
  COOKIES_POLICY = 'polityka-cookies',
}

export enum ROUTE_HASH {
  ANSWER_CARD_MODAL = '#answerCardOpened',
  ANSWER_KEY_MODAL = '#answerKeyOpened',
  COOKIES_POLICY_MODAL = '#cookiesModalOpened',
  DELETE_CARD_MODAL = '#deleteModalOpened',
  PRIVACY_POLICY_MODAL = '#privacyPolicyModalOpened',
  REGULATIONS_MODAL = '#regulationsModalOpened',
  VIDEO_TUTORIAL_MODAL = '#videoTutorialOpened'
}
