import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { onResponseFailure, onResponseSuccess } from '@/services/api/Utils';
import { AuthResponse } from '@/store/models';
import { ApiErrorResponse, RegulationsResponse } from '@/services/api/models/Api';

const API_BASE_PATH = '/api';
const API_LOGIN_PATH = '/user/authorize/credentials';
const API_REGULATIONS_PATH = '/consents/Ondorio-web_regulations';
const API_POLICY_PRIVACY_PATH = '/consents/privacy_policy';
const API_GT_TEST_PATH = '/answer/getKey';

const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000
});

instance.interceptors.response.use(onResponseSuccess, onResponseFailure);
instance.interceptors.request.use((config) => {
  return config
})

export const login = async (email: string, password: string): Promise<AuthResponse> => {
  const request = { login: email, pass: password };
  const res = await instance.post(`${API_BASE_PATH}${API_LOGIN_PATH}`, request);
  return res?.data;
}

export const getRegulations = async (): Promise<RegulationsResponse | ApiErrorResponse> => {
  const res = await instance.get(`${API_BASE_PATH}${API_REGULATIONS_PATH}`)
  return res?.data;
}

export const getPolicyPrivacy = async () => {
  const res = await instance.get(`${API_BASE_PATH}${API_POLICY_PRIVACY_PATH}`)
  return res?.data;
}

export const getTest = async (testId: number, token: string, hash: string) => {
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config
  })
  const res = await instance.get(`${API_BASE_PATH}${API_GT_TEST_PATH}/${hash}`, )
  return res?.data;
}

export default instance;

