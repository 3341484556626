import { computed, ref } from 'vue';
import { AuthState, Env, State } from '@/store/models';
import { Storage } from '@capacitor/storage';
import { StorageEnum } from '@/models';
import { EnvType } from '@/services/api/Env';
import router from '@/router';
import { RouteName } from '@/router/routeNames';

const state = ref<State>({
  app: {
    isFirstAppEnter: true,
    isLogged: false,
    regulationsBody: '',
    privacyPolicyBody: '',
    cookiesPolicyBody: '',
    cookiesPolicyOndorioBody: ''
  },
  auth: {
    email: null,
    hash: null,
    isDirector: false,
    isOndorio: false,
    isTeacher: false,
    name: null,
    status: null,
    token: null,
    userId: ''
  },
  env: {
    apiUrl: '',
    mneUrl: '',
    omrUrl: '',
    env: EnvType.DEV,
    piwikId: '',
    renderUrl: ''
  }
})


/// SETTERS
async function setUserData(data: AuthState) {
  state.value.auth = data;
  await Storage.set({
    key: StorageEnum.IS_TEACHER,
    value: data.isTeacher !== null ? data.isTeacher.toString() : '',
  });

  await Storage.set({
    key: StorageEnum.IS_ONDORIO,
    value: data.isOndorio !== null ? data.isOndorio.toString() : '',
  });

  await Storage.set({
    key: StorageEnum.IS_DIRECTOR,
    value: data.isDirector !== null ? data.isDirector.toString() : '',
  });

  await Storage.set({
    key: StorageEnum.USER_EMAIL,
    value: data.email || '',
  });

  await Storage.set({
    key: StorageEnum.USER_NAME,
    value: data.name || '',
  });

  await Storage.set({
    key: StorageEnum.TOKEN,
    value: data.token || '',
  });

  await Storage.set({
    key: StorageEnum.STATUS,
    value: data.status ? data.status.toString() : '',
  });

  await Storage.set({
    key: StorageEnum.HASH,
    value: data.hash ? data.hash.toString() : '',
  });

  await Storage.set({
    key: StorageEnum.USER_ID,
    value: data.userId ? data.userId.toString() : '',
  });
}

async function saveStorageInfoToStore (): Promise<void> {
  const isLogged = await Storage.get({ key: StorageEnum.IS_LOGGED });
  if (isLogged?.value) await setIsLogged(isLogged?.value === 'true');
}

async function saveIsFirstAppEnterToStore (): Promise<void> {
  await Storage.set({ key: StorageEnum.IS_FIRST_APP_ENTER, value: 'false' });
}

async function setUserDataFromStorage(): Promise<void> {
  const email = await Storage.get({ key: StorageEnum.USER_EMAIL });
  const hash = await Storage.get({ key: StorageEnum.HASH });
  const isDirector = await Storage.get({ key: StorageEnum.IS_DIRECTOR });
  const isFirstAppEnter = await Storage.get({ key: StorageEnum.IS_FIRST_APP_ENTER });
  const isTeacher = await Storage.get({ key: StorageEnum.IS_TEACHER });
  const isOndorio = await Storage.get({ key: StorageEnum.IS_ONDORIO });
  const name = await Storage.get({ key: StorageEnum.USER_NAME });
  const status = await Storage.get({ key: StorageEnum.STATUS });
  const token = await Storage.get({ key: StorageEnum.TOKEN });
  const userId = await Storage.get({ key: StorageEnum.USER_ID });

  state.value.auth = {
    email: email.value,
    hash: hash.value,
    isDirector: isDirector.value === 'true',
    isTeacher: isTeacher.value === 'true',
    isOndorio: isOndorio.value === 'true',
    name: name.value,
    status: Number(status.value),
    token: token.value,
    userId: userId.value
  };
  state.value.app.isFirstAppEnter = isFirstAppEnter.value === 'true' || isFirstAppEnter.value === null;
}

function removeUserDataFromStorage(): void {
  const keysToRemove = [
    StorageEnum.USER_EMAIL,
    StorageEnum.HASH,
    StorageEnum.IS_DIRECTOR,
    StorageEnum.IS_TEACHER,
    StorageEnum.IS_ONDORIO,
    StorageEnum.USER_NAME,
    StorageEnum.STATUS,
    StorageEnum.TOKEN,
    StorageEnum.USER_ID,
  ];
  keysToRemove.forEach(async (key) => {
    await Storage.remove({ key })
  })
}

function resetUserId (): void {
  state.value.auth.userId = '';
}

async function setIsLogged(isLogged: boolean) {
  state.value.app.isLogged = isLogged;
  await Storage.set({
    key: StorageEnum.IS_LOGGED,
    value: isLogged ? 'true' : 'false',
  });
}

async function setEnvs(env: Env) {
  state.value.env = env;
}

function setRegulations(body: string): void {
  state.value.app.regulationsBody = body;
}

function setPrivacyPolicy(body: string): void {
  state.value.app.privacyPolicyBody = body;
}

function setCookiesPolicy(body: string): void {
  state.value.app.cookiesPolicyBody = body;
}

function setOndorioCookiesPolicy(body: string): void {
  state.value.app.cookiesPolicyOndorioBody = body;
}

async function logoutUser (): Promise<void> {
  await setIsLogged(false);
  await router.push(RouteName.LOGIN);
  removeUserDataFromStorage();
}

/// GETTER
const cookiesPolicyBody = computed(() => state.value.app.cookiesPolicyBody)
const cookiesPolicyOndorioBody = computed(() => state.value.app.cookiesPolicyOndorioBody)
const env = computed(() => state.value.env)
const isFirstAppEnter = computed(() => state.value.app.isFirstAppEnter)
const isLogged = computed(() => state.value.app.isLogged)
const loginStatus = computed(() => state.value.auth.status)
const mneUrl = computed(() => state.value.env.mneUrl)
const privacyPolicyBody = computed(() => state.value.app.privacyPolicyBody)
const regulationsBody = computed(() => state.value.app.regulationsBody)
const userData = computed(() => state.value.auth)
const userId = computed(() => state.value.auth.userId)
const userToken = computed(() => state.value.auth.token)

export {
  cookiesPolicyBody,
  cookiesPolicyOndorioBody,
  env,
  isFirstAppEnter,
  isLogged,
  loginStatus,
  mneUrl,
  privacyPolicyBody,
  regulationsBody,
  userData,
  userId,
  userToken,
  logoutUser,
  removeUserDataFromStorage,
  resetUserId,
  saveStorageInfoToStore,
  setCookiesPolicy,
  setEnvs,
  setIsLogged,
  setOndorioCookiesPolicy,
  setPrivacyPolicy,
  setRegulations,
  setUserData,
  setUserDataFromStorage,
  saveIsFirstAppEnterToStore
}
