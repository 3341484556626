export type Platform = 'ios' | 'android' | 'web';

export enum StorageEnum {
  HASH = 'hash',
  IS_DIRECTOR = 'isDirector',
  IS_FIRST_APP_ENTER = 'isFirstAppEnter',
  IS_LOGGED = 'isLogged',
  IS_ONDORIO = 'isOndorio',
  IS_TEACHER = 'isTeacher',
  STATUS = 'status',
  TOKEN = 'token',
  USER_EMAIL = 'userEmail',
  USER_NAME = 'userName',
  USER_ID = 'userId',
}

export enum LoginStatus {
  VERIFICATION_IN_PROGRESS = 1,
  SUCCESS,
  NOT_VERIFIED
}

export enum OmrErrorResponseTextEnum {
  API_GT_ERROR = 'API GT ERROR',
  FILE_IS_EMPTY = 'FILE IS EMPTY',
  PAGE_NOT_READ = 'Page not read',
  QR_NOT_DECODE = 'QRcode not decode',
  STUDENT_ANSWER_NOT_DECODE = 'StudentAnswer not decode',
  TEACHER_ANSWER_NOT_DECODE = 'TeacherAnswer not decode',
  TEST_NOT_FOUND = 'Test not found',
  TEST_VERSION_NOT_FOUND = 'Test version not found',
  UNAUTHORIZED_REQUEST = 'Unauthorized request',
  FILE_IS_TOO_BIG = 'File is too big',
  INCORRECT_FORMAT = 'Incorrect format',
  INTERNAL_SERVER_ERROR = 'server error',
  TAGS_NOT_MATCH = 'Not found tags'
}

export interface ErrorFiles {
  errorType: OmrErrorResponseTextEnum;
  files: string[];
}

export interface FileUploadModel {
  blob: File;
  filesLength: number;
  isMultipleFiles: boolean;
}

export enum ModalWidthEnum {
  MD = 'md',
  LG = 'lg'
}
