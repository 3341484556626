
import { IonContent, IonPage, IonSpinner, IonText } from '@ionic/vue';
import { defineComponent } from 'vue';
import { login } from '@/services/api/ApiGT';
import { AuthResponse } from '@/store/models';
import {
  mneUrl,
  privacyPolicyBody,
  regulationsBody,
  setIsLogged,
  setUserData
} from '@/store';
import { RouteName } from '@/router/routeNames';
import { closeOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { LoginStatus } from '@/models';
import { notify } from '@/utils/NotificationUtils';
import Card from '@/components/shared/Card.vue';
import Header from '@/components/shared/Header.vue';
import Modal from '@/components/shared/Modal.vue';
import { ApiErrorResponse } from '@/services/api/models/Api';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';
import { isMobile } from "@/utils/DeviceUtils";
import HttpStatusCodes from "@/services/common/HttpStatusCodes";


export default defineComponent({
  name: 'Login',
  components: { Modal, Header, IonContent, IonPage, IonSpinner, IonText, Card },
  data() {
    return {
      closeOutline,
      dataLayerEventEnum: DataLayerEvent,
      email: '',
      eyeOffOutline,
      eyeOutline,
      isLoading: false,
      isPrivacyPolicyModalOpen: false,
      isRegulationModalOpen: false,
      isValidationActive: false,
      isValidationInfoVisible: false,
      mneUrl: '',
      password: '',
      showPassword: false,
      dataLayerPush: dataLayerPush
    }
  },
  computed: {
    isEmailEmpty(): boolean {
      return this.isValidationActive && this.email === ''
    },
    isEmailToLong(): boolean {
      return this.email.length > 100 && this.isValidationActive;
    },
    isEmailIncorrectFormat(): boolean {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.isValidationActive && !this.isEmailEmpty && !re.test(String(this.email).toLowerCase());
    },
    isEmailInvalid(): boolean {
      return this.isEmailEmpty || this.isEmailIncorrectFormat || this.isEmailToLong;
    },
    isPasswordToLong(): boolean {
      return this.password.length > 100 && this.isValidationActive;
    },
    isPasswordEmpty(): boolean {
      return this.isValidationActive && this.password === ''
    },
    isLoginPossible(): boolean {
      return !this.isPasswordEmpty && !this.isPasswordToLong && !this.isEmailInvalid;
    },
    isMobile(): boolean {
      return isMobile();
    },
    remindPasswordLink(): string {
      return `${this.mneUrl}/odzyskaj-haslo,${this.email}`
    },
    accountCreateLink(): string {
      return `${this.mneUrl}/zaloz-konto`;
    },
    regulationsBody(): string {
      return regulationsBody.value;
    },
    privacyPolicyBody(): string {
      return privacyPolicyBody.value;
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleEnterLogin);
    this.mneUrl = mneUrl.value;
  },
  ionViewWillEnter() {
    this.resetFormFields();
  },
  ionViewDidLeave() {
    document.removeEventListener('keydown', this.handleEnterLogin);
  },
  methods: {
    resetFormFields(): void {
      this.email = '';
      this.password = '';
      this.showPassword = false;
      this.isValidationActive = false;
      this.isValidationInfoVisible = false;
    },
    handleEnterLogin(e: KeyboardEvent): void {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') this.signIn();
    },
    togglePassword(): void {
      this.showPassword = !this.showPassword;
    },
    redirectUser(res: AuthResponse): void {
      const redirectMap = {
        [LoginStatus.SUCCESS]: RouteName.ANSWER_CARD,
        [LoginStatus.NOT_VERIFIED]: RouteName.NOT_VERIFIED,
        [LoginStatus.VERIFICATION_IN_PROGRESS]: RouteName.NOT_VERIFIED
      }
      const location = res.isOndorio ? redirectMap[(res.status as LoginStatus)] : RouteName.NO_ONDORIO;
      this.$router.push(location);
    },
    handleErrorResponse(e: ApiErrorResponse): void {
      if (e.statusCode === HttpStatusCodes.UNAUTHORIZED) {
        this.isValidationInfoVisible = true;
      }
      dataLayerPush(DataLayerEvent.USER_LOGIN, { isSuccess: false })
    },
    async handleSuccessResponse(res: AuthResponse): Promise<void> {
      await setUserData(res);
      await setIsLogged(true);
      this.redirectUser(res);
      await notify(this.$t('LOGIN.login_success'));
      dataLayerPush(DataLayerEvent.USER_LOGIN, { isSuccess: true })
    },
    async signIn(): Promise<void> {
      try {
        this.isValidationActive = true;
        this.isLoading = true;
        if (this.isLoginPossible) {
          const response = await login(this.email, this.password);
          this.handleSuccessResponse(response);
        }
      } catch (e) {
        this.handleErrorResponse(e as ApiErrorResponse);
      } finally {
        this.isLoading = false
      }
    },
    setRegulationModalOpen(isOpen = true): void {
      this.isRegulationModalOpen = isOpen;
    },
    setPrivacyPolicyModalOpen(isOpen = true): void {
      this.isPrivacyPolicyModalOpen = isOpen;
    },
  }
});
