

import { defineComponent } from 'vue';
import { IonButton, popoverController } from '@ionic/vue';
import { RouteName } from '@/router/routeNames';


export default defineComponent({
  name: 'Menu',
  components: { IonButton },
  props: {
    name: {
      type: String,
      default: ''
    },
    isTeacher: {
      type: Boolean,
      default: false
    },
    isDirector: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },
  methods: {
    onPrivacyModalOpen(): void {
      popoverController.dismiss('onPrivacyModalOpen');
    },
    onRegulationsModalOpen(): void {
      popoverController.dismiss('onRegulationsModalOpen');
    },
    onCookiesModalOpen(): void {
      popoverController.dismiss('');
      this.$router.push(RouteName.COOKIES_POLICY)
    },
    onLogout(): void {
      popoverController.dismiss('onLogout');
    }
  },
})
