import { OmrAnswerCard } from './models/OmrAnswerCard';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { onResponseSuccess, onResponseFailure } from '@/services/api/Utils';
import { userToken } from '@/store';

const OMR_PROCESS_PATH = '/process';

const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_OMR_URL,
  timeout: 80000
});

instance.interceptors.response.use(onResponseSuccess, onResponseFailure);
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${userToken.value}`;
  return config
})

export const OmrAnswerCardUpload = async (formData: FormData): Promise<OmrAnswerCard> => {
  const res = await instance.post(`${OMR_PROCESS_PATH}`, formData);
  return res?.data as OmrAnswerCard;
}

export default instance;
