import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "gt-scores-card" }
const _hoisted_2 = { class: "gt-scores-card__header-body" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.headerName)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ width: _ctx.headerWidthStyle }),
          class: "gt-scores-card__header-wrapper"
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.headerName)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "gt-scores-card__header",
                  innerHTML: _ctx.headerName
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ])
        ], 4))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "body")
  ]))
}