

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    headerName: {
      type: String,
      default: ''
    },
    headerWidth: {
      type: Number,
      default: null
    }
  },
  computed: {
    headerWidthStyle (): string {
      return this.headerWidth ? this.headerWidth + 'px' : 'inherit'
    }
  }
})
