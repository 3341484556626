import { DataLayerAdditionalInfo, DataLayerEvent, DataLayerModel } from '@/services/common/DataLayerModel';
import { env, userId } from '@/store';
import { EnvType } from '@/services/api/Env';

export const dataLayerPush = (dataLayerEvent: DataLayerEvent, { ...rest }: DataLayerAdditionalInfo = {} ): void => {
  // for unit test
  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
  }
  const resetObject = {
    event: 'gtm.piwikReset',
    userId: userId.value,
    answerCardId: null,
    educationLevel: null,
    error: null,
    error1: null,
    fileName: null,
    filesLength: null,
    from: null,
    isFileUpload: null,
    isSuccess: null,
    subject: null,
    tabName: null,
    testId: null,
    to: null,
    videoProgress: null
  };
  (window as any).dataLayer.push({ ...resetObject });
  const dataLayer: DataLayerModel = {
    event: dataLayerEvent,
    userId: userId.value,
    ...rest
  };
  if (env.value.env !== EnvType.PROD) console.log(dataLayer);
  (window as any).dataLayer.push(dataLayer);
}
