// TODO: add notification component for handling errors
import { toastController } from '@ionic/vue';

type Position = "top" | "bottom" | "middle";

export const notify = async (message: string, duration = 4000, position: Position = 'top'): Promise<void> => {
  const notification = await toastController.create({ duration, message, position, cssClass: `toast-bottom` })
  await notification.present();
};

export const notifyError = async (message: string, duration?: number, position: Position = 'top'): Promise<void> => {
  const notification = await toastController.create(
    {
      duration: 3000,
      message,
      position,
      color: 'danger',
      buttons: [
        {
          text: 'Zamknij',
        }
      ],
      cssClass: `toast-bottom`
    })
  await notification.present();
};

export const notifySuccess = async (message: string, duration?: number, position: Position = 'top'): Promise<void> => {
  const notification = await toastController.create(
    {
      duration: 5000,
      message,
      position,
      color: 'success',
      buttons: [
        {
          text: 'Zamknij',
        }
      ],
      cssClass: `toast-bottom`
    })
  await notification.present();
};

export const notifyWarn = async (message: string, duration = 3000, position: Position  = 'top'): Promise<void> => {
  const notification = await toastController.create({ duration, message, position, color: 'warning', cssClass: `toast-bottom` })
  await notification.present();
};
