import { RouteRecordName } from 'vue-router';

export enum DataLayerEvent {
  ADD_ANSWER_CARD = 'gtm.addAnswerCard',
  ANSWER_CARD_MODAL_OPEN = 'gtm.answerCardModal',
  ANSWER_CARD_MODAL_TAB_CHANGE = 'gtm.answerCardModalTabChange',
  ANSWER_KEY_GROUP_CHANGE = 'gtm.answerKeyGroupChange',
  ANSWER_KEY_OPEN = 'gtm.answerKeyOpen',
  CAMERA_CONSENT = 'gtm.cameraConsent',
  CREATE_ACCOUNT = 'gtm.createAccount',
  DELETE_ANSWER_CARD = 'gtm.deleteAnswerCard',
  EXTERNAL_LINK = 'gtm.externalLink',
  LOGOUT = 'gtm.applicationEnd',
  MULTIPLE_UPLOAD = 'gtm.multipleFilesUpload',
  PAGE_VIEW = 'gtm.pageview',
  PASSWORD_REMINDER = 'gtm.passwordReminder',
  RESULT_DOWNLOAD = 'gtm.resultsDownload',
  USER_LOGIN = 'gtm.userLogin',
  USE_BACK_BUTTON = 'gtm.useBackButton',
  STUDENT_NUMBER_HAS_CHANGED = 'gtm.studentNumberChanged',
  STUDENT_SCORE_HAS_CHANGED = 'gtm.studentScoreChanged',
  VIDEO_WATCHED = 'gtm.videoWatched',
  ZOOM = 'gtm.zoom'
}

export interface DataLayerModel {
  event: DataLayerEvent;
  userId: string | null;
}

export interface DataLayerAdditionalInfo {
  answerCardId?: number | string;
  educationLevel?: string;
  error?: string;
  error1?: string;
  fileName?: string;
  filesLength?: number;
  from?: RouteRecordName | null;
  isFileUpload?: boolean;
  isSuccess?: boolean;
  subject?: string;
  tabName?: string;
  testId?: number;
  to?: RouteRecordName | null;
  videoProgress?: string;
}
