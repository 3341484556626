import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { onResponseSuccess, onResponseFailure } from '@/services/api/Utils';
import { userToken } from '@/store';

const COOKIES_POLICY_PATH = '/api/public/v1/rodo/cookies_policy';


const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_OMR_URL,
  timeout: 80000
});

instance.interceptors.response.use(onResponseSuccess, onResponseFailure);
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${userToken.value}`;
  return config
})

export const getPolicyText = async (): Promise<string> => {
  const res = await instance.get(`${COOKIES_POLICY_PATH}`)
  return res?.data.data.attributes.value;
}

export default instance;
