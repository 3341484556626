import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gt-scores-modal" }
const _hoisted_2 = { class: "gt-scores-modal__wrapper" }
const _hoisted_3 = { class: "gt-scores-modal__header" }
const _hoisted_4 = { class: "gt-scores-modal__header-text" }
const _hoisted_5 = { class: "gt-scores-modal__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_modal, {
          "is-open": _ctx.isOpen,
          class: "gt-scores-modal__ion-class gt-scores-modal__ion-class--md",
          cssClass: [_ctx.width === _ctx.widthEnum.LG ? 'gt-scores-modal__ion-class--lg' : ''],
          onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsModalOpen(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.headerText), 1),
                _createElementVNode("div", {
                  "data-cy": "gt-scores-modal__close-btn",
                  class: "gt-scores-modal__header-icon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIsModalOpen(false)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('OTHERS.close')), 1),
                  _createVNode(_component_ion_icon, {
                    name: "close",
                    size: "medium"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ]),
          _: 3
        }, 8, ["is-open", "cssClass"])
      ]),
      _: 3
    })
  ]))
}