
import {
  IonHeader,
  IonLabel,
  IonPage,
  IonPopover,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  modalController,
  popoverController
} from '@ionic/vue';
import { camera, ellipse, logInOutline, square, triangle } from 'ionicons/icons';
import { isLogged, loginStatus, logoutUser, resetUserId, setPrivacyPolicy, setRegulations, userData } from '@/store';
import { defineComponent } from 'vue';
import { notify } from '@/utils/NotificationUtils';
import {ROUTE_HASH, RouteName} from '@/router/routeNames';
import Menu from '@/components/Menu.vue';
import Modal from '@/components/shared/Modal.vue';
import { getPolicyPrivacy, getRegulations } from '@/services/api/ApiGT';
import { ApiErrorResponse, RegulationsResponse } from '@/services/api/models/Api';
import { AuthState } from '@/store/models';
import { LoginStatus } from '@/models';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';
import { RouteRecordName } from "vue-router";


export default defineComponent({
  name: 'Tabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonHeader,
    IonPopover,
    Modal,
    IonRouterOutlet,
  },
  data() {
    return {
      ellipse,
      square,
      triangle,
      logInOutline,
      camera,
      routeName: RouteName,
      isMenuOpen: false,
      isRegulationModalOpen: false,
      isPrivacyPolicyModalOpen: false,
      privacyPolicyBody: '',
      regulationsBody: '',
    }
  },
  computed: {
    isUserLogged(): boolean {
      return isLogged.value
    },
    user(): AuthState {
      return userData.value
    },
    isAnswerCardBtnVisible(): boolean {
      return this.isUserLogged && loginStatus.value === LoginStatus.SUCCESS;
    },
  },
  methods: {
    pushDataLayerExternalLink() {
      dataLayerPush(DataLayerEvent.EXTERNAL_LINK)
    },
    ionViewDidEnter() {
      window.addEventListener('popstate', () => {
        dataLayerPush(DataLayerEvent.USE_BACK_BUTTON)
      })
      this.getRegulations();
      this.getPrivacyPolicy();
    },
    async openMenu(ev: Event) {
      const popover = await popoverController
        .create({
          component: Menu,
          componentProps: {
            name: userData.value.name,
            isDirector: userData.value.isDirector,
            isTeacher: userData.value.isTeacher,
            email: userData.value.email,
          },
          cssClass: 'menu',
          event: ev,
          translucent: true
        })
      await popover.present();
      const { data } = await popover.onDidDismiss();
      this.handleMenuPopoverDismiss(data as string);
    },
    handleMenuPopoverDismiss(name: string) {
      switch (name) {
        case 'onPrivacyModalOpen':
          this.setPrivacyPolicyModalOpen()
          break;
        case 'onRegulationsModalOpen':
          this.setRegulationModalOpen();
          break;
        case 'onLogout':
          this.logoutUser();
          break;
      }
    },
    async logoutUser(): Promise<void> {
      dataLayerPush(DataLayerEvent.LOGOUT);
      resetUserId();
      await logoutUser();
      await notify(this.$t('OTHERS.logout_success'));
    },

    async getRegulations(): Promise<void> {
      const response = await getRegulations();
      try {
        this.handleRegulationsSuccessResponse(response as RegulationsResponse)
      } catch (e) {
        await this.handleErrorResponse(response as ApiErrorResponse);
      }
    },
    async getPrivacyPolicy(): Promise<void> {
      const response = await getPolicyPrivacy();
      try {
        this.handlePolicyPrivacyPolicySuccessResponse(response as RegulationsResponse)
      } catch (e) {
        await this.handleErrorResponse(response as ApiErrorResponse);
      }
    },
    handleRegulationsSuccessResponse(response: RegulationsResponse) {
      this.regulationsBody = response.message;
      setRegulations(response.message)
    },
    handlePolicyPrivacyPolicySuccessResponse(response: RegulationsResponse) {
      this.privacyPolicyBody = response.message;
      setPrivacyPolicy(response.message)
    },
    async handleErrorResponse(e: ApiErrorResponse) {
      await notify(e.message)
    },
    setPrivacyPolicyModalOpen(isOpen = true): void {
      isOpen ?
          this.$router.push({name: this.$route.name as RouteRecordName, hash: ROUTE_HASH.PRIVACY_POLICY_MODAL}) :
          this.$router.replace({name: this.$route.name as RouteRecordName, hash: ''});
      this.isPrivacyPolicyModalOpen = isOpen;
    },
    setRegulationModalOpen(isOpen = true): void {
      isOpen ?
          this.$router.push({name: this.$route.name as RouteRecordName, hash: ROUTE_HASH.REGULATIONS_MODAL}) :
          this.$router.replace({name: this.$route.name as RouteRecordName, hash: ''});
      this.isRegulationModalOpen = isOpen;
    }
  },
  watch: {
    $route(to, from) {
      switch (from.hash) {
        case ROUTE_HASH.REGULATIONS_MODAL:
          this.isRegulationModalOpen = false;
          break;
        case ROUTE_HASH.PRIVACY_POLICY_MODAL:
          this.isPrivacyPolicyModalOpen = false;
          break;
      }
    }
  }
})
