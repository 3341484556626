import axios from 'axios';
import { Env } from '@/store/models';


export enum EnvType {
  DEV = 'DEV',
  INT = 'INT',
  TST = 'TST',
  PREPROD = 'PREPROD',
  PROD = 'PROD'
}

export interface EnvConfig {
  MNE_URL: string;
  API_URL: string;
  OMR_URL: string;
  ENV: EnvType;
  PIWIK_ID: string;
  RENDER_URL: string;
}

export const getEnvVariables = async (): Promise<Env> => {
  let envConfig: EnvConfig = {
    MNE_URL: "",
    API_URL: "",
    OMR_URL: "",
    ENV: EnvType.DEV,
    PIWIK_ID: "",
    RENDER_URL: ""
  };
  try {
    envConfig = (await axios.get<EnvConfig>('/environment.json')).data;
  } catch (e) {
    console.log(e);
  }

  return {
    env: envConfig.ENV,
    apiUrl: envConfig.API_URL,
    mneUrl: envConfig.MNE_URL,
    omrUrl: envConfig.OMR_URL,
    piwikId: envConfig.PIWIK_ID,
    renderUrl: envConfig.RENDER_URL
  };
};
