import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { EnvType } from '@/services/api/Env';


const initSentry = (env: EnvType) => {
  const IS_PRE_OR_PRD_MODE = env === EnvType.PROD || env === EnvType.PREPROD;
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: process.env.VUE_APP_VERSION || '',
      integrations: [new Integrations.BrowserTracing()],
      environment: (env as string),
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: IS_PRE_OR_PRD_MODE ? 0 : 1,
    });
  }
};

export default initSentry;
