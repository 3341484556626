

import { defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';
import { RouteName } from '@/router/routeNames';


export default defineComponent({
  components: { IonButton },
  methods: {
    goToHomePage(): void {
      this.$router.push(RouteName.HOME)
    }
  }
})
